/* v8 ignore start - nothing to test here */

/**
 * Add polyfills to the global scope.
 * This function should be called once in the application.
 * FIXME: this should be added by the whole oneweb
 */
if (!Array.prototype.at) {
  Array.prototype.at = function at(n) {
    n = Math.trunc(n) || 0;
    if (n < 0) n += this.length;
    if (n < 0 || n >= this.length) return undefined;
    return this[n];
  };
}
